import { InterviewModel } from "../../model";
import React from "react";

interface InterviewProps {
  data: InterviewModel[];
}

const InterviewAllResults: React.FC<InterviewProps> = ({ data }) => {
  const getStatusClass = (status: string) => {
    switch (status) {
      case "Scheduled":
        return "text-orange-500";
      case "Completed":
        return "text-green-500";
      case "Cancelled":
        return "text-red-500";
      default:
        return "";
    }
  };

  return (
    <div className="p-6 rounded-[32px] shadow-lg">
      <table className="w-full text-left table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Designation</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Date</th>
            <th className="px-4 py-2">Time</th>
            <th className="px-4 py-2">Invite</th>
          </tr>
        </thead>
        <tbody>
          {data.map((result, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="border px-4 py-2 flex items-center">
                <img src={result.avatar} alt="Avatar" className="w-8 h-8 rounded-full mr-4" />
                {result.name}
              </td>
              <td className="border px-4 py-2">{result.designation}</td>
              <td className={`border px-4 py-2 ${getStatusClass(result.status)}`}>{result.status}</td>
              <td className="border px-4 py-2">{result.date?.toLocaleDateString()}</td>
              <td className="border px-4 py-2">{result.date?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</td>
              <td className="border px-4 py-2">{result.invite ? result.invite : "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InterviewAllResults;
