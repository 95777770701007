import React from "react";
import DashboardCompanyComponent1 from "./components/companyComponent1";
import DashboardCompanyComponent2 from "./components/companyComponent2";

import ComponentAdvisor2 from "./components/componentAdvisor2";
import RecommendedCandidates from "./components/recommendedCandidates";
import AssignedTasksComponent from "./components/assignedTasksComponent";
import SurveysFeedbackComponent from "./components/surveysFeedbackComponent";
import UpcomingScheduleComponent from "./components/upcomingScheduleComponent";
import "./dashboard.css";
import PerformanceAnalysis1 from "./components/performanceAnalysis1";
import PerformanceAnalysis from "./components/performanceAnalysis";
import RemunerationOverview from "./components/remunerationOverview";
import OfferAndOnboarding from "./components/offerAndOnboarding";

function CompanyDashboard() {
  return (
    <>
      <div className="max-w-[1800px] px-[30px] lg:pl-[90px] py-[33px] min-h-screen h-full bg-[#F4F4F4]">
        {/* Üstteki başlık ve zaman seçici */}
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-10">
          <div className="flex flex-col gap-[5px]">
            <h4 className="nunitomedium text-[30px] text-[#2B2F42]">
              Good morning, Emre
            </h4>
            <h4 className="nunitomedium text-[14px] text-[#2B2F42]">
              Take a look at your work done during the month.
            </h4>
          </div>
        </div>

        {/* İki ana sütun yapısı: Sol sütun ve sağda UpcomingSchedule */}
        <div className="flex flex-col lg:flex-row">
          {/* Sol sütun: Open Postings, diğer metrikler, Recommended Candidates, Assigned Tasks, Surveys */}
          <div className="flex-1 space-y-10">
            {/* Open Postings ve diğer metrikler */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* <DashboardCompanyComponent1
                title="Open Postings"
                percentagevalue="45,34%"
                description="During this month"
              /> */}
              <DashboardCompanyComponent2
                title="Candidate-Advertisement "
                count="23%"
                description="During this month"
                icon="Group 2574"
              />
              <DashboardCompanyComponent2
                title="Completed Recruitments"
                count="18%"
                description="During this month"
                icon="Group 2575"
              />
            </div>

            <div className="col-span-1 md:col-span-3 border p-4 mt-10">
              <h5
                className={
                  "nunitomedium text-left sm:text-start text-[30px] text-[#2B2F42]"
                }
              >
                Performance Analysis
              </h5>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <PerformanceAnalysis />
              <PerformanceAnalysis1 />
            </div>
            <div>
              <p className="nunitomedium text-left text-[30px] text-[#2B2F42] mt-20">
                Recommended Candidates
              </p>

              <RecommendedCandidates />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
              <RemunerationOverview />
              <OfferAndOnboarding />
            </div>
          </div>
          {/* Sağ sütun: Upcoming Schedule */}
          <div className="w-full lg:w-[400px] lg:mt-0 lg:ml-8">
            <div className="flex flex-row gap-[18px] mt-6 lg:mt-0">
              <div className="px-[28px] select-none cursor-pointer text-[14px] text-white py-[10px] bg-[#FE0000] bg-opacity-90 rounded-[32px] flex justify-center items-center">
                30 days
              </div>
              <div className="px-[28px] select-none cursor-pointer text-[#2B2F42] bg-opacity-0 text-[14px] py-[10px] bg-[#FE0000] rounded-[32px] flex justify-center items-center">
                90 days
              </div>
              <div className="text-[#2B2F42] select-none cursor-pointer bg-opacity-0 text-[14px] py-[10px] bg-[#FE0000] rounded-[32px] flex justify-center items-center">
                6 months
              </div>
            </div>
            <UpcomingScheduleComponent />
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDashboard;
