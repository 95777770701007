import React from 'react';
import { InterviewRecordsModel } from '../../model';

interface InterviewRecordProps {
    data: InterviewRecordsModel[];
}

const InterviewRecords: React.FC<InterviewRecordProps> = ({ data}) => {

    return (
        <div className="p-6 rounded-[32px] shadow-lg">
            <table className="w-full text-left table-auto">
                <thead>
                    <tr>
                        <th className="px-4 py-2">Interview Candidates</th>
                        <th className="px-4 py-2">Type of Interview</th>
                        <th className="px-4 py-2">Status</th>
                        <th className="px-4 py-2">Date</th>
                        <th className="px-4 py-2">Time</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((record, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                            <td className="border px-4 py-2 flex items-center">
                                <img src={record.avatar} alt="Avatar" className="w-8 h-8 rounded-full mr-4" />
                                {record.name}
                            </td>
                            <td className="border px-4 py-2">Video Conferencing</td>
                            <td className="border px-4 py-2 text-green-500">{record.status}</td>
                           <td className="border px-4 py-2">{record.date.toLocaleDateString()}</td>
                            <td className="border px-4 py-2">{record.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default InterviewRecords;
