import "./job.css"

import { useEffect, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { JobPostModel } from '@/api/models/JobPostResponse';
import { getAll } from '@/api/services/postsService';

interface CreateJobPostProps {
    height: string;
}

function CreatejobPost(props: CreateJobPostProps) {
    const [tabledata, setTabledata] = useState<JobPostModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                setLoading(true);
                const response = await getAll();

                setTabledata(response.Data);
            } catch (err) {
                setError('Veri yüklenirken hata oluştu');
            } finally {
                setLoading(false);
            }
        };

        fetchCandidates();
    }, []);

    const statusBodyTemplate = (rowData: JobPostModel) => {
        const statusClass = rowData.post.postStatus === 1 ? 'status-Active' : 'status-Deactive';
        return (
            <div className={statusClass}>
                {rowData.post.postStatus == 1 ? "Active" : "Deactive"}
            </div>
        );
    };

    const jobPostTemplate = (rowData: JobPostModel) => {
        return (
            <div className="flex">
                <div className="flex flex-col justify-center items-center mr-2">
                    {rowData.post.companyLogo ? (
                        <img src={rowData.post.companyLogo} alt="Icon" style={{ width: '40px', height: '40px' }} />
                    ) : (
                        <img src="/media/logo-small.svg" alt="Icon" style={{ width: '40px', height: '40px' }} />
                    )}
                </div>
                <div className="flex flex-col">
                    <span>{rowData.post.jobTitle}</span>
                    <span className="text-xs text-gray-500">{rowData.post.companyName}</span>
                </div>
            </div>
        );
    };


    const DeadlinebodyTemplate = (rowData: JobPostModel) => {
        const scheduleDate = new Date(rowData.post.scheduleDate);
        const currentDate = new Date();

        const timeDiff = scheduleDate.getTime() - currentDate.getTime();
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        const oneday = dayDiff === 1;
        return (
            <div className="flex gap-2">
                <div className="flex flex-col">
                    <span>{`${dayDiff} Days`}</span>
                    <span className="text-xs text-gray-500">{oneday ? 'Last' : 'Ago'}</span>
                </div>
                <div className="flex align-items-center">
                    {oneday && (
                        <img src="/media/svg/general/report.svg" alt="Icon" style={{ width: '24px', height: '24px' }} />
                    )}
                </div>
            </div>
        );
    };

    const ApplicationTemplate = (rowData: JobPostModel) => {
        return (
            <div className="flex flex-col align-items-start gap-1">
                <div className="flex align-items-center gap-2">
                    <span>0</span>
                </div>
                <span className="text-xs text-gray-500">Application</span>
            </div>
        );
    }

    const rowClass = (data: JobPostModel) => {
        return {
            "custom-row": true
        };
    };

    return (
        <div className="container">
            <div className="rectangle">
                <DataTable value={tabledata} scrollable scrollHeight={props.height} rowClassName={rowClass} className="w-full mt-[5px] ml-[5px] mr-[5px] custom-data">
                    <Column field="jobPost" header="Job Post" body={jobPostTemplate}></Column>
                    <Column field="experience" header="Experience" ></Column>
                    <Column field="deadline" header="Deadline" body={DeadlinebodyTemplate}></Column>
                    <Column field="status" header="Status" body={statusBodyTemplate} ></Column>
                    <Column field="applicationrecords" header="Application Records" body={ApplicationTemplate} ></Column>
                </DataTable>
            </div>
        </div>

    );
}

export default CreatejobPost;