import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/candidates' icon='switch' title='Candidates' fontIcon='bi-layers' />
      <SidebarMenuItem to='/jobs' icon='switch' title='Jobs' fontIcon='bi-layers' />
      <SidebarMenuItem to='/job-postings' icon='switch' title='Job Postings' fontIcon='bi-layers' />
      <SidebarMenuItem to='/interview-management' icon='switch' title='Interview Management' fontIcon='bi-layers' />
      <SidebarMenuItem to='/onboarding' icon='switch' title='Onboarding' fontIcon='bi-layers' /> 
      <SidebarMenuItem to='/administration' icon='switch' title='Administration' fontIcon='bi-layers' /> 
       
    </>
  )
}

export {SidebarMenuMain}
