import React, { useState, useEffect, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import axios from 'axios';  // Axios'u import etmeyi unutma
import { AppointmentModel, ParticipantModel } from '../../model';  // Eğer Appointment modeli kullanıyorsanız

interface InterviewCalendarProps {
  participants: ParticipantModel[];
  appointments: AppointmentModel[];
}

const InterviewCalendar: React.FC<InterviewCalendarProps> = ({ participants, appointments }) => {
  const [date, setDate] = useState<Date | null>(null);  // Tarih seçimi için state
  const [time, setTime] = useState('09:00 - 18:00');  // Saat aralığı için state
  const inviteModalRef = useRef<HTMLDivElement | null>(null);  // Modal referansı
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(null); // Seçilen katılımcı

  const API_PUT_INVITE = "https://api.example.com/interviews";  // API endpoint'ini buraya ekle

  // Save butonuna tıklandığında verileri PUT isteği ile gönderme
  const handleSubmitInvite = async () => {
    if (date && time && selectedCandidate) {
      const payload = {
        candidateId: selectedCandidate,
        date: date.toISOString(),
        timeSlot: time,
      };

      try {
        // PUT isteği ile verileri gönderiyoruz
        const response = await axios.put(`${API_PUT_INVITE}/${selectedCandidate}`, payload);
        console.log("Data successfully sent:", response.data);

        // İşlem başarılı olduğunda modal'ı kapat
        setIsOpen(false);
      } catch (error) {
        console.error("Error sending data:", error);
      }
    }
  };

  // Modal dışında bir yere tıklandığında modal'ı kapat
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inviteModalRef.current && !inviteModalRef.current.contains(event.target as Node)) {
        setIsOpen(false);  // Modal dışında tıklandığında kapat
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);  // Tıklama olayını dinle
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);  // Temizle
    };
  }, [isOpen]);

  return (
    <>
      <div className="p-6 rounded-[32px] shadow-lg grid gap-4" style={{ gridTemplateColumns: 'minmax(0, 2fr) minmax(0, 1fr)' }}>
        {/* Left Side - Time Slots and Interviews */}
        <div className="flex-1">
          <h3 className="text-[16px] text-center font-semibold text-gray-900 mb-4">
            {new Date().toLocaleDateString()}
          </h3>
          <div className="flex flex-col gap-2 overflow-y-auto max-h-[300px] pr-4">
            {appointments.map((appointment, index) => (
              <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-lg shadow-md">
                <div className="text-gray-700 text-sm">
                  <div className="flex flex-col text-right">
                    <span>{appointment.timeSlot.startTime}</span>
                    <span>{appointment.timeSlot.endTime}</span>
                  </div>
                </div>
                <div className="flex items-center p-2 rounded-lg shadow-inner flex-1 ml-4">
                  <img
                    src={appointment.candidate.avatarUrl}
                    alt="Candidate"
                    className="w-8 h-8 rounded-full mr-4"
                  />
                  <div className="text-gray-700 text-sm flex-1">
                    <span className="block font-semibold">
                      {appointment.candidate.name}
                    </span>
                    <span className="block text-xs text-gray-500">
                      {appointment.candidate.position}
                    </span>
                  </div>
                </div>
                <div className="ml-4">
                  <img
                    src="/media/svg/general/more-options.svg"
                    alt="More Options"
                    className="w-6 h-6"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {isOpen && (
          <div ref={inviteModalRef} className="z-10 absolute bg-white shadow-lg p-6 rounded-lg mt-2 w-[400px] right-40">
            {/* Katılımcı Seçimi */}
            <select
              value={selectedCandidate || ''}
              onChange={(e) => setSelectedCandidate(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled>Select a participant</option>
              {participants.map((participant, index) => (
                <option key={index} value={participant.id}>
                  {participant.name}
                </option>
              ))}
            </select>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <span
                className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >{date?.toLocaleDateString()}</span>
              <input
                type="text"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                placeholder="09:00 - 18:00"
                className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <button
              onClick={handleSubmitInvite}
              className="w-full p-3 bg-red-500 text-white rounded-lg hover:bg-red-600 mt-4"
            >
              Save
            </button>
          </div>
        )}

        {/* Right Side - Calendar */}
        <div className="w-full">
          <div className="card flex justify-content-center">
            <Calendar
              value={date}
              onChange={(e) => {
                setDate(e.value || null);
                setIsOpen(true);
              }}
              inline
              showWeek
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewCalendar;
