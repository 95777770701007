import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateJobPost3 = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        // Bir sonraki sekmeye ilerler
        navigate('/createjobpostlayout/step4');
    };

    return (
        <div className="p-6">
            {/* Başlık ve açıklama */}
            <h2 className="font-nunito text-[22px] font-medium leading-[30.01px] text-left mb-2">
                Additional Questions
            </h2>
            <p className="font-nunito text-[14px] font-medium leading-[20px] text-left text-gray-600 mb-4">
                Choose one or more questions to ask for your candidate.
            </p>

            {/* Layout */}
            <div className="w-full h-full bg-white rounded-[20px] border border-gray-300 p-6 overflow-y-auto shadow-lg">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[200px] gap-y-6 mt-10 ml-10">
                    {/* Sol kısım */}
                    <div className="space-y-4 justify-center items-center">
                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    How many years of work experience do you have with Figma?*
                                </p>
                                <input
                                    type="text"
                                    className="block w-[421px] h-[36px] p-2 border border-gray-300 rounded-[10px] mt-2"
                                    placeholder="Enter answer"
                                />
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    How many years of work experience do you have with AdobeXD?*
                                </p>
                                <input
                                    type="text"
                                    className="block w-[421px] h-[36px] p-2 border border-gray-300 rounded-[10px] mt-2"
                                    placeholder="Enter answer"
                                />
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    How many years of work experience do you have with HTML and CSS?*
                                </p>
                                <input
                                    type="text"
                                    className="block w-[421px] h-[36px] p-2 border border-gray-300 rounded-[10px] mt-2"
                                    placeholder="Enter answer"
                                />
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    How many years of work experience do you have with Prototyping?*
                                </p>
                                <input
                                    type="text"
                                    className="block w-[421px] h-[36px] p-2 border border-gray-300 rounded-[10px] mt-2"
                                    placeholder="Enter answer"
                                />
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    What is your expectations of Salary?*
                                </p>
                                <input
                                    type="text"
                                    className="block w-[421px] h-[36px] p-2 border border-gray-300 rounded-[10px] mt-2"
                                    placeholder="Enter answer"
                                />
                            </div>
                        </label>
                    </div>

                    {/* Sağ kısım */}
                    <div className="space-y-4 justify-center items-center">
                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    Have you completed the following level of education: Bachelor’s Degree?*
                                </p>
                                <div className="mt-2 space-x-4">
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="education" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">Yes</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="education" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">No</span>
                                    </label>
                                </div>
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    Are you legally authorized to work in Germany?*
                                </p>
                                <div className="mt-2 space-x-4">
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="authorized" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">Yes</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="authorized" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">No</span>
                                    </label>
                                </div>
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    Will you now, or in the future, require sponsorship for employment visa status (e.g.)?*
                                </p>
                                <div className="mt-2 space-x-4">
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="visa" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">Yes</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="visa" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">No</span>
                                    </label>
                                </div>
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    Will you now, or in the future, require sponsorship for employment visa status (e.g.)?*
                                </p>
                                <div className="mt-2 space-x-4">
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="visa" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">Yes</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="visa" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">No</span>
                                    </label>
                                </div>
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left">
                                    Will you now, or in the future, require sponsorship for employment visa status (e.g.)?*
                                </p>
                                <div className="mt-2 space-x-4">
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="visa" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">Yes</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input type="radio" name="visa" className="form-radio h-5 w-5 text-red-600" />
                                        <span className="ml-2">No</span>
                                    </label>
                                </div>
                            </div>
                        </label>

                        <label className="flex items-start">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600 mr-4 mt-1" />
                            <div>
                                <input
                                    type="text"
                                    className="block w-[530px] h-[36px] p-2 border border-gray-300 rounded-[10px] mt-2"
                                    placeholder="Write your question here."
                                />
                                <div className="flex justify-end">
                                    <button className="bg-red-500 text-white w-[130px] h-[50px] rounded-lg mt-2 ml-auto">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            {/* Sabitlenen Sonraki Buton */}
            <div className="fixed bottom-10 right-10">
                <button onClick={handleNext} >
                <img src="/public/media/svg/general/ileriok.svg" alt="Next" className="w-30 h-30" />
                </button>
            </div>
        </div>
    );
};

export default CreateJobPost3;
