import { toAbsoluteUrl } from "../../../../_metronic/helpers";
 

interface Props {
  title: string;
  count: string;
  description: string;
}

function DashboardComponent1({ title, count, description }: Props) {

    
    return ( <>
    <div className={"min-w-[346px] w-[346px]  h-[243px] rounded-[32px] relative"}>
                <div className={"absolute flex flex-col gap-[29px] py-[23px] pl-[22px] z-20"}>
                  <h6 className={"nunitomedium text-[30px] select-none text-white"}>{title}</h6>
                  <div className={"flex flex-col w-full gap-[6px]"}>
                    <h6 className={"nunitomedium leading-10 select-none w-fit ml-[20px] text-center text-[30px] text-white"}>
                      {count} 
                    </h6>
                    <h6 className={"nunitomedium leading-4 select-none -ml-2 mt-4 w-[170px]  text-center text-[14px] text-white"}>{description}</h6>
                  </div>
                </div>
                 <img className={"absolute  right-0 bottom-6 z-10"} src={toAbsoluteUrl("media/light-favicon.svg")} />

                <div className={"w-full h-full absolute -top-6 -left-2 z-10 flex justify-center items-center"}>
                  <img src={toAbsoluteUrl("media/dashboard/search-job-icon-1.svg")} />
                </div>

                <img className={"absolute z-20 right-8 top-[2px]"} src={toAbsoluteUrl("media/dashboard/search-job-icon-2.svg")} />
                
                <img className={"w-[346px] object-cover absolute -z-0 left-0 top-0"} src={toAbsoluteUrl("media/dashboard/search-job-bg.svg")} />
                 

                 
              </div>
    </> );
}

export default DashboardComponent1;