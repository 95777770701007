function PerformanceAnalysis() {
    return (
        <>
            <div className={"relative flex flex-col w-full"}>
                <div className={"flex flex-col lg:flex-row w-full"}>
                    <div className={"w-[575px] pb-[40px] pr-[20px] rounded-[32px] flex flex-col bg-[#F4F4F4] min-h-[266px] pt-[27px] pl-[21px] shadowds1 relative"}>
                        <div className={"flex flex-col lg:flex-row items-center"}>
                            <img src={"/media/svg/general/Vector.svg"} className={"ml-6 mr-6 w-[60px] h-[60px]"} />
                            <p className={"nunitomedium text-left text-[22px] text-[#2B2F42]"}>Average Listing <br /> Closure Time</p>
                        </div>
                        <div>
                            <p className={"nunitomedium ml-6 text-left text-[14px] text-[#2B2F42]"}>Monthly</p>
                        </div>
                        <div className={"flex flex-col gap-12 lg:gap-0 lg:flex-row justify-between items-center"}>
                            <div className={"flex flex-col gap-2 items-center"}>
                                <img src={"/media/svg/general/Group.svg"} className={"ml-6 mr-6 w-[285px] h-[125px]"} />
                            </div>
                        </div>
                        <div className={"absolute top-0 right-0 bottom-0  flex flex-col justify-between items-center"}>
                        <img src={"/media/svg/general/Rectangle 1766.svg"} className={"absolute top-0 right-0 bottom-0 w-full h-full object-cover rounded-tr-[33px] rounded-br-[33px]"} />
                        <div className={"flex flex-col justify-center items-center h-full py-4"}>
                                <img src={"/media/svg/general/Group 2581.svg"} className={"w-[183px] h-[39px] mb-4 z-10 mb-8 pl-2"} />
                                <img src={"/media/svg/general/Line 2.svg"} className={"w-[183px] mb-4 z-10 mb-8 pl-2"} />
                                <img src={"/media/svg/general/Group 2581.svg"} className={"w-[183px] h-[39px] mb-4 z-10 mb-8 pl-2"} />
                                <img src={"/media/svg/general/Line 2.svg"} className={"w-[183px] mb-4 z-10 mb-8 pl-2"} />
                                <img src={"/media/svg/general/Group 2581.svg"} className={"w-[183px] h-[39px] z-10 pl-2"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PerformanceAnalysis;
