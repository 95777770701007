import { useEffect, useState } from 'react'

import { CurrentUser } from '@/api/models/Login/CurrentUser'
import { HeaderUserMenu } from '../../../partials'
import { KTIcon } from '../../../helpers'
import clsx from 'clsx'
import { useAuth } from "@/app/modules/auth/core/Auth";
import { useLayout } from '../../core'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const { config } = useLayout()
  const { currentUser } = useAuth()

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className="symbol symbol-40px mr-10 ">
            <span className={`symbol-label bg-[#FE0000] text-white fw-bold`}>{currentUser?.name.toUpperCase().charAt(0)}</span>

          </div>
        </div>
        <div>
          {currentUser?.name} {currentUser?.surname}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }
