import React from 'react';

const UpcomingScheduleComponentCandidate: React.FC = () => {
    const schedules: any[] = []; // Boş schedule verisi

    return (
        <div className="p-6 max-w-[400px]">
            <h2 className="text-2xl font-semibold text-[#2B2F42] mb-4">Upcoming Schedule</h2>
            <div className="flex items-center justify-between mb-8">
                <button className="text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                <h3 className="text-lg font-semibold text-[#2B2F42]">Today, 26 March</h3>
                <button className="text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>

            {schedules.length === 0 ? (
                <div className="text-center">
                    <p className="text-gray-500 mb-4">There is no upcoming schedule for today.</p>
                    <img
                        src="media/svg/general/OBJECTS.svg"
                        alt="No Schedule"
                        className="w-[250px] mx-auto mb-6"
                    />
                    <button className="bg-red-500 text-white rounded-full px-6 py-2 flex items-center justify-center mx-auto">
                        <span className="text-xl font-bold mr-2">+</span>
                        <span>Create</span>
                    </button>
                </div>
            ) : (
                schedules.map((schedule, index) => (
                    <div key={index} className="flex items-start mb-8">
                        <div className={`border-l-4 ${schedule.borderColor} rounded-b rounded-t pl-2 pr-4`}>
                            <div className="flex items-center">
                                <img src={schedule.icon} alt={schedule.name} className="w-12 h-12 rounded-full mr-4 ml-2" />
                                <div className={`flex-shrink-0 w-10 h-10 rounded-full mb-4 ${schedule.statusColor} flex items-center justify-center text-white font-bold`}>
                                    <span>+</span>
                                </div>
                            </div>
                            <div className="ml-4">
                                <p className="font-semibold text-lg text-[#2B2F42]">{schedule.name}</p>
                                <p className="text-gray-500 text-sm">{schedule.role}</p>
                                <p className={`font-semibold ${schedule.timeColor} mt-2`}>{schedule.time}</p>
                                <p className="text-gray-500 text-sm">{schedule.details}</p>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default UpcomingScheduleComponentCandidate;
