import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { RecommendedJobModel } from "../models/recommended-job-model";

interface RecommendedJobProps {
    data: RecommendedJobModel;
  }
  
  function RecommendedJob({ data }: RecommendedJobProps) {
    return ( <>
        <div className="border p-4">
                <div className={"w-full justify-between gap-[25px] w-full    rounded-[32px] flex flex-col lg:flex-row w-full bg-[#F4F4F4] min-h-[200px]  shadowds1"}>
                  <div className={" pb-[23px] flex flex-row gap-[25px] w-fit h-fit items-center rounded-[32px] pr-[43px] pt-[16px] pl-[22px]"}>
                    <div className={"flex flex-col items-center gap-[10px]"}>
                      <div className={"w-[74px] flex h-[74px] bg-white rounded-full fex justify-center items-center"}>
                         
                        <img className={"w-[70px] h-[70px] rounded-full"} src={toAbsoluteUrl(data.companyLogo)} alt="" />
                      </div>
                      <h6 className={"nunitomedium text-[14px] text-opacity-40 text-[#2B2F42]"}>{data.company}</h6>
                    </div>
                    <div className={"flex flex-col"}>
                      <h6 className={"text-[22px] nunitoregular text-[#2B2F42]"}>{data.jobTitle}</h6>
                      <h6 className={"text-[14px] text-opacity-40 nunitoregular text-[#2B2F42]"}>
                        {data.location} <br /> ({data.type})
                      </h6>
                      <div className={"mt-[25px] flex gap-[5px] flex-row items-center"}>
                        <img className={"w-[42px] h-[42px] rounded-full"} src={toAbsoluteUrl(data.image)} alt="" />
                        <h6 className={"text-[11px] text-opacity-40 nunitomedium text-[#2B2F42]"}>Your profile matches this job</h6>
                      </div>
                    </div>
                  </div>
                  <div className={"bg-[#F0F0F0] px-[15px] py-8 gap-4 lg:gap-0 rounded-b-[32px] lg:items-start items-center lg:py-[15px] h-full flex flex-col justify-between items-start   lg:rounded-r-[32px] w-full lg:w-[40%]"}>
                    <div className={"w-full flex justify-between items-center flex-row"}>
                      <h6 className={"nunitomedium text-[16px] text-[#2B2F42] text-opacity-40"}>{data.daysAgo}</h6>
                    </div>
                    <div className={" flex flex-col gap-[8px]"}>
                      <h6 className={"nunitomedium text-[20px] text-[#2B2F42] text-opacity-40"}>{data.employeeCount} Employees</h6>
                      <h6 className={"nunitomedium text-[20px] text-[#2B2F42] text-opacity-40"}>{data.percentage} match your profile</h6>
                      <div></div>
                    </div>
                    <div className={"flex flex-row items-center gap-2"}>
                      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2273 0H1.77273C1.30257 0 0.851671 0.184374 0.51922 0.512563C0.186769 0.840752 0 1.28587 0 1.75V14L6.5 7.61425L13 14V1.75C13 1.28587 12.8132 0.840752 12.4808 0.512563C12.1483 0.184374 11.6974 0 11.2273 0Z" fill="#4339F2" />
                      </svg>
                      <p className={"nunitomedium text-[12px] text-[#4339F2] "}>You saved this job</p>
                    </div>
                  </div>
                </div>
              </div>
    </> );
}

export default RecommendedJob;