import React from 'react';
import {InterviewModel} from '../../model';

interface InterviewProps {
    data: InterviewModel[];
}

const InterviewFeatured: React.FC<InterviewProps> = ({ data }) => {
    return (
        <div className=" p-6 rounded-[32px] shadow-lg">
            <div className="overflow-x-auto">
                <table className="min-w-full">
                    <thead>
                        <tr>
                            <th className="text-left text-gray-700 text-xl font-semibold px-6 py-4">Name</th>
                            <th className="text-left text-gray-700 text-xl font-semibold px-6 py-4">Designation</th>
                            <th className="text-left text-gray-700 text-xl font-semibold px-6 py-4"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((i, index) => (
                            <tr key={index} className="border-b">
                                <td className="flex items-center px-6 py-4">
                                    <img src={`${i.avatar} + ${index + 1}.jpg`} alt="Candidate" className="w-10 h-10 rounded-full mr-4" />
                                    <span>{i.name}</span>
                                </td>
                                <td className="px-6 py-4">{i.designation}</td>
                                <td className="px-6 py-4">
                                    <span className="bg-[#4339f2] text-white py-1 px-4 rounded-full">{i.status}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InterviewFeatured;
