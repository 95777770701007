import { toAbsoluteUrl } from "../../../../_metronic/helpers";

interface Props {
  title: string;
  count: string;
  description: string;
  icon: string;
}

function DashboardComponent2({ title, count, description, icon }: Props) {
  return (
    <>
      <div
        className={
          "w-[346px] cursor-pointer bg-[white] relative flex-col pt-[20px] pl-[21px] flex h-[230px] rounded-[32px] shadowds1 bg-[#F4F4F4]"
        }
      >
        <div className={"flex flex-row gap-[24px]"}>
          <div
            className={
              "py-[18px] w-fit h-fit rounded-full bg-opacity-10 flex justify-center items-center bg-[#C5BDBD]"
            }
          >
            <img src={toAbsoluteUrl("media/svg/general/" + icon + ".svg")} />
          </div>
          <p
            className={
              "nunitomedium leading-[35px] text-[30px] select-none text-[#2B2F42] py-[12px]"
            }
          >
            {title}
          </p>
        </div>

        <div className={"mt-[30px] flex items-center w-full"}>
          <div className={"flex flex-col w-[170px]"}>
            <p
              className={
                "nunitomedium leading-[35px] text-[30px] select-none text-[#2B2F42] ml-10 whitespace-nowrap"
              }
            >
              {count}
            </p>
            <h6
              className={
                "nunitomedium text-[#2B2F42] text-opacity-[72%] leading-4 select-none mt-1 ml-10 text-center text-[14px] "
              }
            >
              {description}
            </h6>
          </div>
          <img
            src={"/media/svg/general/calendar_month.svg"}
            className={"ml-[100px] w-[24px] h-[24px] svg-black"}
            alt="Calendar"
          />
        </div>
      </div>
    </>
  );
}

export default DashboardComponent2;
