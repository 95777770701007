import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateJobPost4 = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        // Bir sonraki sayfaya ilerle
        navigate('/createjobpostlayout/complete');
    };

    return (
        <div className="p-6">
            {/* Başlık */}
            <h2 className="font-nunito text-[22px] font-medium leading-[30.01px] text-left mb-2">
                Publish Post
            </h2>
            <p className="font-nunito text-[14px] font-medium leading-[20px] text-left text-gray-600 mb-4">
                Copy and paste this code into your website. <a href="#" className="text-blue-600">Learn more...</a>
            </p>

            {/* Link input */}
            <div className="relative mb-6">
                <img src="/public/media/svg/general/link.svg" alt="Link" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
                <input
                    type="text"
                    className="block w-[728px] h-[48px] pl-12 p-3 border border-gray-300 rounded-lg ml-10"
                    value="https://www.jobwars.de/jobs"
                />
            </div>

            <p className="font-nunito text-[14px] font-medium leading-[20px] text-left text-gray-600 mb-4">
                Share this on your favorite social networks
            </p>

            {/* Sosyal medya kartları */}
            <div className="flex space-x-4 mb-6">
                <div className="w-[204px] h-[243px] rounded-lg flex items-center justify-center">
                    <img src="/public/media/svg/general/linkedincard.svg" alt="LinkedIn" className="w-[200px] h-[250px]" />
                </div>
                <div className="w-[204px] h-[243px] rounded-lg flex items-center justify-center">
                    <img src="/public/media/svg/general/xingcard.svg" alt="Xing" className="w-[200px] h-[250px]" />
                </div>
                <div className="w-[204px] h-[243px] rounded-lg flex items-center justify-center">
                    <img src="/public/media/svg/general/googlecard.svg" alt="Google" className="w-[200px] h-[250px]" />
                </div>
            </div>

            {/* Onay kutusu */}
            <div className="flex items-center mb-6">
                <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-600 mr-2" />
                <p className="font-nunito text-[14px] text-gray-600">
                    I've read and agree with <a href="#" className="text-blue-600">Opti Recruits</a> terms of service and our privacy policy
                </p>
            </div>

            {/* Tarih ve butonlar */}
            <div className="flex flex-col space-y-4">
                <p className="font-nunito text-[14px] font-medium leading-[20px] text-left text-gray-600">
                    Choose date share later
                </p>
                <div className="flex items-center space-x-4">
                    <select className="block w-[150px] p-3 border border-gray-300 rounded-lg">
                        <option>Choose Date</option>
                    </select>
                    <button className="bg-red-500 text-white w-[130px] h-[50px] rounded-lg">
                        Publish
                    </button>
                    <button className="text-gray-600 w-[100px] h-[50px] rounded-lg">
                        Cancel
                    </button>
                </div>
            </div>

            {/* Preview kartı */}
            <div className="absolute right-0 top-[300px] w-[376px] h-[519px] bg-[#F4F4F4] rounded-[20px] shadow-lg border border-gray-300 p-6">
                <h2 className="font-nunito text-[18px] font-medium leading-[25px] text-center mb-4">
                    Preview
                </h2>
                <div className="flex items-center justify-center mb-6">
                    <img src="/public/media/svg/general/prevcard.svg" alt="Company Logo" className="w-16 h-16" />
                </div>
                <div className="text-center">
                    <p className="font-nunito text-[18px] font-medium text-gray-700">Slack</p>
                    <p className="font-nunito text-[22px] font-semibold text-gray-800 mb-2">UI/UX Designer</p>
                    <p className="font-nunito text-[16px] text-gray-500">London</p>
                    <p className="font-nunito text-[14px] text-gray-500 mb-4">0-4 Years • 15-90 Employees</p>
                    <div className="flex flex-wrap justify-center space-x-2 mb-4">
                        <span className="bg-[#F0F0F0] text-gray-700 px-2 py-1 rounded-lg">User Research</span>
                        <span className="bg-[#F0F0F0] text-gray-700 px-2 py-1 rounded-lg">Wireframe</span>
                        <span className="bg-[#F0F0F0] text-gray-700 px-2 py-1 rounded-lg">Mockups</span>
                        <span className="bg-[#F0F0F0] text-gray-700 px-2 py-1 rounded-lg">Figma</span>
                        <span className="bg-[#F0F0F0] text-gray-700 px-2 py-1 rounded-lg">Adobe XD</span>
                    </div>
                    <div className="flex justify-center space-x-4">
                        <span className="bg-pink-100 text-black px-4 py-2 rounded-lg">Full Time</span>
                        <span className="bg-orange-100 text-black px-4 py-2 rounded-lg">Remote</span>
                    </div>
                </div>
            </div>

            {/* Sabitlenen Sonraki Buton */}
            <div className="fixed bottom-10 right-10">
                <button onClick={handleNext} >
                    <img src="/public/media/svg/general/ileriok.svg" alt="Next" className="w-30 h-30" />
                </button>
            </div>
        </div>
    );
};

export default CreateJobPost4;
