import { toAbsoluteUrl } from "../../../../_metronic/helpers";

interface Props {
    title: string;
    description: string;
    icon: string;
    number: number;
  }

function DashboardComponent2( {title,description,icon,number} : Props) {
    return ( 
    <>
          <div className={"w-[350px] cursor-pointer bg-[white] relative flex-col pt-[20px] pl-[21px] flex h-[230px] rounded-[32px] shadowds1 bg-[#F4F4F4]"}>
          
          <div className={"flex flex-row gap-[24px]"}>
            <div className={"py-[18px] w-fit h-fit rounded-full bg-opacity-10 flex justify-center items-center bg-[#C5BDBD]"}>
                    <img src={toAbsoluteUrl("media/icons/"+ icon +".svg")} />
                  </div>
                  <h6 className={"nunitomedium leading-[35px] text-[30px] select-none text-[#2B2F42] py-[12px]"}>
                    {title}
                  </h6>
                </div>
                <div className={"mt-[30px] flex flex-col w-[170px]"}>
                  <h6 className={"nunitomedium w-full leading-10 select-none w-fit  text-center text-[40px] text-[#2B2F42]"}>{number}</h6>
                  <h6 className={"nunitomedium text-[#2B2F42] text-opacity-[72%] leading-4 select-none mt-1   text-center text-[14px] "}>{description}</h6>
                </div>
                <svg className={"absolute fill-[#2B2F42] opacity-[25%] right-0 bottom-0 z-10"} width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.2">
                    <path fillRule="evenodd" clipRule="evenodd" d="M69.9607 132.877C63.3714 132.441 56.971 130.927 50.8843 128.36C43.823 125.382 37.4819 121.119 32.0379 115.691C26.594 110.262 22.3195 103.939 19.3319 96.8985C16.2386 89.6064 14.6699 81.8627 14.6699 73.8833C14.6699 65.9039 16.2386 58.1602 19.3319 50.8669C22.3183 43.8259 26.594 37.5031 32.0379 32.0748C37.4819 26.6466 43.823 22.3843 50.8843 19.4054C58.1975 16.321 65.9635 14.7568 73.966 14.7568C81.9685 14.7568 89.7345 16.321 97.0477 19.4054C104.109 22.3832 110.45 26.6466 115.894 32.0748C121.338 37.5031 125.613 43.8259 128.6 50.8669C131.693 58.1591 133.262 65.9027 133.262 73.8833C133.262 76.5009 131.134 78.6231 128.509 78.6231C125.883 78.6231 123.755 76.5009 123.755 73.8833C123.755 67.1789 122.44 60.6783 119.844 54.5604C117.337 48.6487 113.747 43.3389 109.171 38.7781C104.596 34.2172 99.2723 30.6363 93.3435 28.1361C87.208 25.5481 80.6886 24.2364 73.9648 24.2364C67.241 24.2364 60.7216 25.5481 54.5861 28.1361C48.6573 30.6363 43.3322 34.2172 38.7582 38.7781C34.1841 43.3389 30.5929 48.6487 28.0854 54.5604C25.49 60.6783 24.1745 67.1789 24.1745 73.8833C24.1745 80.5877 25.49 87.0883 28.0854 93.2062C30.5929 99.1179 34.1841 104.428 38.7582 108.989C43.3322 113.549 48.6573 117.13 54.5861 119.631C60.1292 121.969 65.9856 123.265 72.023 123.494C73.2341 122.603 74.7311 122.077 76.3514 122.077C80.3832 122.077 83.6516 125.336 83.6516 129.356C83.6516 133.376 80.3832 136.635 76.3514 136.635C73.6006 136.635 71.2051 135.118 69.9607 132.877Z" fill="" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M56.1377 91.6679C60.9007 96.4159 67.2335 99.0323 73.9691 99.0323V99.0335C79.1762 99.0335 84.1425 97.4706 88.329 94.5671L106.873 112.823C108.032 113.963 109.54 114.532 111.048 114.532C112.581 114.532 114.114 113.944 115.276 112.77C117.582 110.442 117.558 106.69 115.223 104.391L96.2422 85.7055C98.1659 82.1112 99.1863 78.0782 99.1863 73.8889C99.1863 67.1726 96.5636 60.8581 91.8006 56.1088C87.0376 51.3583 80.7048 48.7432 73.9691 48.7432C67.2335 48.7432 60.9007 51.3583 56.1377 56.1076C51.3747 60.8569 48.752 67.1715 48.752 73.8877C48.752 80.604 51.3747 86.9186 56.1377 91.6679ZM63.7004 63.6462C66.4432 60.9114 70.0903 59.4053 73.9691 59.4053C77.848 59.4053 81.4951 60.9114 84.2378 63.6462C86.9806 66.3811 88.491 70.0177 88.491 73.8854C88.491 77.753 86.9806 81.3896 84.2378 84.1245C81.4951 86.8593 77.848 88.3654 73.9691 88.3654C70.0903 88.3654 66.4432 86.8593 63.7004 84.1245C60.9577 81.3896 59.4473 77.753 59.4473 73.8854C59.4473 70.0177 60.9577 66.3811 63.7004 63.6462Z" fill="" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M69.4696 107.013C70.8394 105.401 72.8846 104.377 75.1694 104.377C79.2937 104.377 82.6372 107.711 82.6372 111.824C82.6372 115.936 79.2937 119.27 75.1694 119.27C72.8452 119.27 70.7689 118.211 69.3993 116.551C65.2028 116.111 61.1214 115.061 57.2174 113.415C52.0932 111.253 47.493 108.161 43.5429 104.223C39.5927 100.284 36.4911 95.6974 34.3247 90.588C32.0799 85.296 30.9414 79.677 30.9414 73.8873C30.9414 68.0977 32.0799 62.4787 34.3247 57.1867C36.4923 52.0772 39.5939 47.4903 43.5429 43.5515C47.493 39.6127 52.0932 36.52 57.2174 34.3599C62.5247 32.1215 68.1599 30.9863 73.9663 30.9863C79.7727 30.9863 85.4079 32.1215 90.7152 34.3599C95.8394 36.5212 100.44 39.6139 104.39 43.5515C108.34 47.4903 111.442 52.0772 113.608 57.1867C115.853 62.4787 116.991 68.0977 116.991 73.8873C116.991 76.5049 114.863 78.6271 112.238 78.6271C109.613 78.6271 107.484 76.5049 107.484 73.8873C107.484 64.9599 103.998 56.567 97.6671 50.2547C91.3367 43.9425 82.9195 40.4659 73.9663 40.4659C65.0131 40.4659 56.5959 43.9425 50.2655 50.2547C43.935 56.567 40.4484 64.9599 40.4484 73.8873C40.4484 82.8147 43.935 91.2077 50.2655 97.5199C55.5234 102.763 62.221 106.049 69.4696 107.013Z" fill="" />
                  </g>
                </svg>

                 
              </div>
    </> );
}

export default DashboardComponent2;