import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const WaveformChart = () => {
  const options: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 4,
      colors: ["#00FF00"],
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Waveform",
      data: [10, 20, 15, 30, 10, 25, 15, 20],
    },
  ];

  return (
    <div className="absolute right-[15px] -top-[24px]">
      <Chart
        options={options}
        series={series}
        type="line"
        height={80}
        width={135}
      />
    </div>
  );
};

const RecommendedCandidatesCard: React.FC = () => {
  return (
    <div className="w-[100%] h-max-content sm:h-[278px] rounded-[32px] flex flex-col sm:flex-row justify-between relative py-[30px] px-[22px] bg-[#f4f4f4] shadow-custom overflow-hidden mb-[2%]">
      {/* Sağ Tarafın Arka Plan Gölgesi */}
      <img
        src={"/media/svg/general/Rectangle 1766.svg"}
        className="absolute hidden sm:block top-[0px] right-[0px] w-[197px] h-[278px] object-cover rounded-tr-[32px] rounded-br-[32px] opacity-1"
        alt="Background Shadow"
      />

      {/* Sol Taraf */}
      <div className="flex flex-col justify-between items-center sm:items-start z-10">
        {/* Logo ve Bilgi */}
        <div className="flex items-start gap-[20.24px]">
          {/* Logo */}
          <div className="w-[70px] h-[74px] relative bg-white rounded-full">
            <img
              src="/media/svg/general/Vector (2).svg"
              alt="Logo"
              className="w-[44px] h-[32px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
          <div>
            <h3 className="nunitomedium text-[14px] leading-[20px] text-[#2B2F42] opacity-40 mb-[1px]">
              Discord
            </h3>
            <p className="nunitomedium text-[22px] leading-[30px] text-[#2B2F42] mb-0">
              UI/UX Designer
            </p>
            <p className="nunitomedium text-[14px] leading-[20px] text-[#2B2F42] opacity-40 mb-[10px]">
              London, England
            </p>
            <p className="nunitomedium text-[14px]  leading-[20px] text-[#2B2F42] opacity-40">
              0-4 Years
              <br /> 11-50 Employees
            </p>
          </div>
        </div>

        {/* Yetenekler */}
        <div className="flex flex-wrap max-w-[280px]">
          {["User Research", "Wireframe", "Mockups", "Figma", "Adobe Xd"].map(
            (skill, index) => (
              <span
                key={index}
                className="nunitomedium text-[10px] leading-[15px] text-[#2B2F42] bg-[#F0F0F0] text-center mr-2 mb-2 px-2.5 py-0.5 rounded h-[24px] flex justify-center items-center"
              >
                {skill}
              </span>
            )
          )}
        </div>

        {/* İş Türü */}
        <div className="flex mt-[18px] ml-2">
          <span className="font-nunito text-[10px] font-normal leading-[15px] text-[#2B2F42] bg-[#FED7FA] text-center mr-2 px-2.5 py-0.5 rounded h-[24px] flex justify-center items-center">
            Full Time
          </span>
          <span className="font-nunito text-[10px] font-normal leading-[15px] text-[#2B2F42] bg-[#FFE5D3] text-center px-2.5 py-0.5 rounded h-[24px] flex justify-center items-center">
            Remote
          </span>
        </div>
      </div>

      {/* Sağ Taraf */}
      <div className="flex flex-col items-center justify-between z-10 mt-[10px] sm:mt-[0px]">
        {/* Profil Resmi */}
        <div className="w-[44px] h-[44px] rounded-full bg-gray-200 mb-4 mr-[0px] sm:mr-[100px]">
          <img
            src="/media/svg/general/avatar.svg"
            alt="Profile"
            className="w-full h-full"
          />
        </div>
        <div className="text-center">
          <p className="nunitomedium text-[14px] leading-[20px] text-[#2B2F42] mr-[0px] sm:mr-20">
            Gabriel Albert
          </p>
          <p className="nunitomedium text-[22px] leading-[30px] text-[#2B2F42] mr-[0px] sm:mr-[100px]">
            $204
          </p>
          <p className="nunitomedium text-[40px] leading-[54.56px] text-[#14ED44] mt-0 mr-[0px] sm:mr-16 mb-0">
            100%
          </p>
        </div>
        {/* Grafik */}
        <div className="w-[135px] h-[45px] mr-[0px]  sm:mr-8 relative">
          <WaveformChart />
        </div>
      </div>
    </div>
  );
};

export default RecommendedCandidatesCard;
