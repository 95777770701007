import "./dashboard.css";

import React, { useEffect, useState } from 'react';

import DashboardComponent1 from "./components/component-1";
import DashboardComponent2 from "./components/component-2";
import ProfileViewers from "./components/profile-viewers";
import RecommendedJob from "./components/component-3";
import { RecommendedJobModel } from "./models/recommended-job-model";
import ResumeEvaluation from "./components/resume-evaluation";
import UpcomingScheduleComponentCandidate from "./UpcomingScheduleComponentCandidate";
import axios from 'axios';
import { useAuth } from "@/app/modules/auth/core/Auth";

// State için varsayılan değerler
const defaultRecommendedJob: RecommendedJobModel = {
  jobTitle: '',
  location: '',
  employeeCount: '',
  daysAgo: '',
  image: '',
  percentage: '',
  company: '',
  type: '',
  companyLogo: ''
};

function CandidateDashboard() {
  const [employers, setEmployers] = useState<string[]>([]);
  const [percentage, setPercentage] = useState<number>(0);
  const [viewed, setViewed] = useState<number>(0);
  const [number, setNumber] = useState<number>(0);
  const [recommendedJob, setRecommendedJob] = useState<RecommendedJobModel>(defaultRecommendedJob);
  const { currentUser } = useAuth()

  const API_GET_EMPLOYERS = "https://api.example.com/employers";
  const API_GET_PROFILE_VIEW = "https://api.example.com/profile-view";
  const API_GET_COMPLETED_RECRUITMENTS = "https://api.example.com/completed-recruitments";
  const API_GET_RECOMMENDED_JOB = "https://api.example.com/recommended-job";

  useEffect(() => {
    const fetchEmployers = async () => {
      try {
        const response = await axios.get<string[]>(API_GET_EMPLOYERS);
        setEmployers(response.data);
      } catch (error) {
        console.error("Error fetching employers:", error);
      }
    };

    const fetchProfileView = async () => {
      try {
        const response = await axios.get<{ percentage: number, viewed: number }>(API_GET_PROFILE_VIEW);
        setPercentage(response.data.percentage);
        setViewed(response.data.viewed);
      } catch (error) {
        console.error("Error fetching profile viewers:", error);
      }
    };

    const fetchCompletedRecruitments = async () => {
      try {
        const response = await axios.get<{ number: number }>(API_GET_COMPLETED_RECRUITMENTS);
        setNumber(response.data.number);
      } catch (error) {
        console.error("Error fetching completed recruitments:", error);
      }
    };

    const fetchRecommendedJob = async () => {
      try {
        const response = await axios.get<RecommendedJobModel>(API_GET_RECOMMENDED_JOB);
        setRecommendedJob(response.data);
      } catch (error) {
        console.error("Error fetching recommended job:", error);
      }
    };

    fetchEmployers();
    fetchProfileView();
    fetchCompletedRecruitments();
    fetchRecommendedJob();
  }, []);

  return (
    <>
      <div className="max-w-[1800px] px-[30px] lg:pl-[90px] py-[33px] min-h-screen h-full ">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-10">
          <div className="flex flex-col gap-[5px]">
            <h4 className="nunitomedium text-[30px] text-[#2B2F42]">Good morning,  {currentUser ? currentUser.name : 'Guest'}</h4>
            <h4 className="nunitomedium text-[14px] text-[#2B2F42]">
              Take a look at your work done during the month.
            </h4>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex-1 space-y-10">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <DashboardComponent1
                title="Open Postings"
                description="During this month"
                count={""}
              />
              <DashboardComponent2
                title="Candidate-Advertisement"
                description="During this month"
                icon="Group 2574"
                number={number}
              />
              <DashboardComponent2
                title="Completed Recruitments"
                description="During this month"
                icon="Group 2575"
                number={number}
              />
            </div>
            <div className="col-span-1 md:col-span-3 border p-4 mt-10">
              <h5 className={"nunitomedium sm:text-start text-[30px] text-[#2B2F42]"}>
                AI-powered CV Assessment
              </h5>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 col-span-1 md:col-span-3">
              <div className="border p-4">
                <ResumeEvaluation employers={employers} />
              </div>
              <div className="border p-4">
                <ProfileViewers percentage={percentage} viewed={viewed} />
              </div>
            </div>

            <div className="col-span-1 md:col-span-3 border p-4">
              <h5 className={"nunitomedium sm:text-start text-[30px] text-[#2B2F42]"}>
                Recommended Jobs
              </h5>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 col-span-1 md:col-span-3">
              <RecommendedJob data={recommendedJob} />
              <RecommendedJob data={recommendedJob} />
            </div>
          </div>

          <div className="w-full lg:w-[400px] lg:mt-0 lg:ml-8">
            <div className="flex flex-row gap-[18px] mt-6 lg:mt-0">
              <div className="px-[28px] select-none cursor-pointer text-[14px] text-white py-[10px] bg-[#FE0000] bg-opacity-90 rounded-[32px] flex justify-center items-center">
                30 days
              </div>
              <div className="px-[28px] select-none cursor-pointer text-[#2B2F42] bg-opacity-0 text-[14px] py-[10px] bg-[#FE0000] rounded-[32px] flex justify-center items-center">
                90 days
              </div>
              <div className="text-[#2B2F42] select-none cursor-pointer bg-opacity-0 text-[14px] py-[10px] bg-[#FE0000] rounded-[32px] flex justify-center items-center">
                6 months
              </div>
            </div>
            <UpcomingScheduleComponentCandidate />
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateDashboard;
