function PerformanceAnalysis1() {
    return (
        <>
            <div className={"relative flex flex-col w-full"}>
                <div className={"flex flex-col lg:flex-row w-full"}>
                    <div className={" lg:w-[575px] pb-[40px] pr-[20px] rounded-[32px] flex flex-col bg-[#F4F4F4] min-h-[266px] pt-[27px] pl-[21px] shadowds1 relative"}>
                        <div className={"flex flex-col lg:flex-row items-center"}>
                            <img src={"/media/svg/general/Vector (1).svg"} className={"ml-6 mr-6 w-[60px] h-[60px]"} />
                            <p className={"nunitomedium text-left text-[22px] text-[#2B2F42]"}>Companies' Listing <br /> Closure Statistics</p>
                        </div>
                        
                        <div className={"flex flex-col gap-12 lg:gap-0 lg:flex-row justify-between items-center"}>
                            <div className={"flex flex-col gap-2 items-center"}>
                                <img src={"/media/svg/general/Group 2581 (1).svg"} className={"ml-6 mr-6 w-[450px] h-[52px] mb-4"} />
                                <img src={"/media/svg/general/Line 21.svg"} className={"ml-6 mr-6 w-[485px] mb-4"} />
                                <img src={"/media/svg/general/Group 25871.svg"} className={"ml-6 mr-6 w-[450px] h-[52px] mb-2"} />
                                <img src={"/media/svg/general/Line 21.svg"} className={"ml-6 mr-6 w-[485px]"} />

                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default PerformanceAnalysis1;
