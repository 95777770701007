import { AppointmentModel, DashboardCardData, InterviewDashBoardModel, InterviewModel, InterviewRecordsModel, ParticipantModel } from '../model';
import React, { useEffect, useState } from 'react';

import InterviewAllResults from './component/interviewAllResults';
import InterviewCalendar from './component/interviewCalendar';
import InterviewCancelled from './component/interviewCancelled';
import InterviewCancelled1 from './component/interviewCancelled';
import InterviewFeatured from './component/interviewFeatured';
import InterviewRecords from './component/interviewRecords';
import { Role } from '../enums/candidateEnums';
import { Status } from '../enums/interviewEnums';
import axios from 'axios';

const Interview: React.FC = () => {

  const [interviewList, setInterviewList] = useState<InterviewModel[]>([]);

  const [recordList, setRecordList] = useState<InterviewRecordsModel[]>([
    {
      name: 'Jack Sparrow',
      status: Status.Completed,
      date: new Date(),
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    }
  ]);

  const [participants, setParticipants] = useState<ParticipantModel[]>([]);
  const [appointments, setAppointments] = useState<AppointmentModel[]>([]);


  const API_URL = import.meta.env.VITE_APP_API_URL;

  const API_GET_INTERVIEW = `${API_URL}/services/app/Invites/Get`;
  const API_GET_INTERVIEW_LIST = `${API_URL}/services/app/Invites/GetAll`;
  const API_GET_INTERVIEW_RECORD = `${API_URL}/services/app/Invites/GetRecords`;
  const API_GET_PARTICIPANTS = `${API_URL}/services/app/Invites/GetParticipants`;
  const API_GET_APPOINTMENTS = `${API_URL}/services/app/Invites/GetAppointments`;

  let dashboardData: DashboardCardData[] = [
    {
      title: "Interviews",
      status: "Delayed",
      color: "purple-200",
      icon: "/media/svg/general/interviews.svg",
      label: "Completed",
      subLabel: 60,
      backgroundClass: "bg-[#4339f2]",
    },
    {
      title: "In Progress",
      status: "Delayed",
      color: "orange-100",
      icon: "/media/svg/general/in-progress.svg",
      label: "Today",
      subLabel: 12,
      backgroundClass: "bg-[#FF8E01]",
    },
    {
      title: "Records",
      status: "On track",
      color: "blue-100",
      icon: "/media/svg/general/records.svg",
      label: "Completed",
      subLabel: 81,
      backgroundClass: "bg-[#36C5F1]",
    },
    {
      title: "Cancelled",
      status: "Moderate",
      color: "red-100",
      icon: "/media/svg/general/cancelled.svg",
      label: "Today",
      subLabel: 2,
      backgroundClass: "bg-red-500",
    },
  ];

  const fetchInterviewDashboard = async () => {
    try {
      const response = await axios.get<InterviewDashBoardModel>(API_GET_INTERVIEW);
      const data = response.data;

      dashboardData = dashboardData.map((card) => {
        if (card.title === "Interviews") {
          return { ...card, subLabel: data.interviews };
        } else if (card.title === "In Progress") {
          return { ...card, subLabel: data.inProgress };
        } else if (card.title === "Records") {
          return { ...card, subLabel: data.records };
        } else if (card.title === "Cancelled") {
          return { ...card, subLabel: data.cancelled };
        }
        return card;
      });

    } catch (error) {
      console.error("Error fetching interview dashboard:", error);
    }
  };

  const fetchInterviewList = async () => {
    try {
      const response = await axios.get(API_GET_INTERVIEW_LIST);
      console.log(response.data)
      setInterviewList(response.data?.result?.items);
    } catch (error) {
      console.error("Error fetching interview list:", error);
    }
  };

  const fetchRecordList = async () => {
    try {
      const response = await axios.get<InterviewRecordsModel[]>(API_GET_INTERVIEW_RECORD);
      setRecordList(response.data);
    } catch (error) {
      console.error("Error fetching record list:", error);
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await axios.get<ParticipantModel[]>(API_GET_PARTICIPANTS);
      setParticipants(response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await axios.get<AppointmentModel[]>(API_GET_APPOINTMENTS);
      setAppointments(response.data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    // fetchInterviewDashboard();
    // fetchInterviewList();
    // fetchRecordList();
    // fetchParticipants();
    // fetchAppointments();
  }, []);

  return (
    <div className="p-8 bg-gray-50 min-h-screen font-nunito text-lg text-gray-800">
      {/* Interview Management Header */}
      <div className="flex justify-between items-center mb-2">
        <div>
          <h1 className="text-[30px] font-semibold leading-[35px] text-left text-[#2B2F42]">
            Interview Management
          </h1>
          <p className="text-[14px] font-semibold leading-[20px] text-left text-[#2B2F42] opacity-60">
            Manage your interviews.
          </p>
        </div>
        <button className="bg-[#F9F9F9] text-[14px] font-semibold leading-[20px] text-left text-[#2B2F42] rounded-t-[32px] rounded-b-[32px] py-2 px-4 shadow-md flex items-center">
          Send Invitation
          <img src="/media/svg/general/plus.svg" alt="Plus Icon" className="ml-2 w-8 h-8" />
        </button>
      </div>

      <div className="grid grid-cols-4 gap-6 mb-6">
        {dashboardData.map((card, index) => (
          <div key={index} className="rounded-[32px] shadow-lg relative bg-white">
            <div className={`${card.backgroundClass} rounded-t-[32px] p-4 flex items-center justify-between`}>
              <p className="text-white text-[22px] font-semibold">{card.title}</p>
              <img src={card.icon} alt={`${card.title} Icon`} className="w-8 h-8" />
            </div>
            <div className="p-6 flex justify-between items-center">
              <img src="/media/svg/general/progress-circle.svg" alt="Progress Circle" className="w-[88px] h-[88px]" />
              <div className="flex flex-col items-end">
                <p className="text-[#2B2F42] text-[14px] font-semibold">{card.label}</p>
                <h3 className="text-[30px] font-bold text-[#2B2F42]">{card.subLabel}</h3>
                <span className={`bg-${card.color} text-${card.color.split("-")[0]}-700 text-[11px] px-2 py-1 rounded-full mt-2`}>
                  {card.status}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Featured & Calendar Sections */}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-900">Featured</h2>
          <InterviewFeatured data={interviewList.filter(i => i.type === "featured")} />
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-900">Calendar</h2>
          <InterviewCalendar appointments={appointments} participants={participants} />
        </div>
      </div>

      <div className="gap-6 mb-6">
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-900">All Results</h2>
          <InterviewAllResults data={interviewList} />
        </div>
      </div>

      <div className="gap-6 mb-6">
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-900">Records</h2>
          <InterviewRecords data={recordList} />
        </div>
      </div>

      <div className="gap-6 mb-6">
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-900">Cancelled</h2>
          <InterviewCancelled1 data={interviewList.filter(i => i.type === "cancelled")} />
        </div>
      </div>
    </div>
  );
};

export default Interview;
