import React, { useState } from "react";
import DashboardCompanyComponent1 from "./components/companyComponent1";
import ComponentAdvisor2 from "./components/componentAdvisor2";
import RecommendedCandidates from "./components/recommendedCandidates";
import AssignedTasksComponent from "./components/assignedTasksComponent";
import SurveysFeedbackComponent from "./components/surveysFeedbackComponent";
import UpcomingScheduleComponent from "./components/upcomingScheduleComponent";
import OfferAndOnboarding from "./components/offerAndOnboarding";
import RemunerationOverview from "./components/remunerationOverview";
import "./dashboard.css";

function AdvisorDashboard() {
  const [selected, setSelected] = useState("30 days");

  const isSelected = (value: string) =>
    selected === value
      ? "text-[#f9f9f9] bg-[#FE0000E6] shadow-custom-red"
      : "text-[#2B2F42] shadow-custom-black bg-opacity-0";
  return (
    <>
      <div className="max-w-[1800px] py-[33px] min-h-screen h-full bg-[#F4F4F4]">
        {/* Üstteki başlık ve zaman seçici */}
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-[20px]">
          <div className="flex flex-col gap-[5px]">
            <h4 className="nunitomedium text-[30px] text-[#2B2F42] mb-0">
              Good morning, Emre
            </h4>
            <h4 className="nunitomedium text-[14px] text-[#2B2F42] mb-0">
              Take a look at your work done during the month.
            </h4>
          </div>
        </div>

        {/* İki ana sütun yapısı: Sol sütun ve sağda UpcomingSchedule */}
        <div className="flex flex-col xl-custom:flex-row justify-between ">
          {/* Sol sütun: Open Postings, diğer metrikler, Recommended Candidates, Assigned Tasks, Surveys */}
          <div className=" space-y-10 min-w-[56.15%] pr-0 lg:pr-[20px]">
            {/* Open Postings ve diğer metrikler */}
            <div className="flex flex-col lg:flex-row gap-[20px]">
              <DashboardCompanyComponent1
                title="Open Postings"
                percentagevalue="45,34%"
                description="During this month"
                route="/job-posting"
                caret={true}
                caretText="2.1%"
              />
              <ComponentAdvisor2
                title="Candidate-Advertisement Matches"
                count="23%"
                description="During this month"
                icon="user-search"
                route="/candidate-management"
                caret={false}
                caretText="2.1%"
              />
              <ComponentAdvisor2
                title="Completed Recruitments"
                count="18%"
                description="During this month"
                icon="user-check"
                route="/candidate-management"
                caret={false}
                caretText="2.1%"
              />
            </div>

            {/* Recommended Candidates */}
            <div>
              <p className="nunitomedium text-left text-[30px] text-[#2B2F42]">
                Recommended Candidates
              </p>
              <RecommendedCandidates />
            </div>

            {/* Assigned Tasks ve Surveys */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <OfferAndOnboarding />
              <RemunerationOverview />
              <SurveysFeedbackComponent />
            </div>
          </div>

          {/* Sağ sütun: Upcoming Schedule */}
          <div className="w-full xl-custom:w-[400px] shrink-0 min-w-[18.65%]">
            <div className="flex flex-row gap-[18px] mt-6 lg:mt-0">
              <div
                onClick={() => setSelected("30 days")}
                className={`flex-1 px-[28px] select-none cursor-pointer text-[14px] py-[10px] rounded-[32px] flex justify-center items-center h-[52px] transition duration-400 text-center ${isSelected(
                  "30 days"
                )}`}
              >
                30 days
              </div>

              <div
                onClick={() => setSelected("90 days")}
                className={`flex-1 px-[28px] select-none cursor-pointer text-[14px] py-[10px] rounded-[32px] flex justify-center items-center h-[52px] transition duration-400 text-center ${isSelected(
                  "90 days"
                )}`}
              >
                90 days
              </div>

              <div
                onClick={() => setSelected("6 months")}
                className={`flex-1 px-[28px] select-none cursor-pointer text-[14px] py-[10px] rounded-[32px] flex justify-center items-center h-[52px] transition duration-400 text-center ${isSelected(
                  "6 months"
                )}`}
              >
                6 months
              </div>
            </div>
            <UpcomingScheduleComponent />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvisorDashboard;
