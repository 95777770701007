
interface Props {
  employers: string[];
}

function ResumeEvaluation({employers}: Props) {
    return ( <>
        <div className={"2xl:w-full w-full lg:w-[521px]  pb-[23px] pr-[43px] rounded-[32px] flex flex-col w-full bg-[#F4F4F4] min-h-[266px] pt-[27px] pl-[21px] shadowds1"}>
                  <h5 className={"nunitomedium text-[30px] text-[#2B2F42]"}>Resume Evaluation</h5>
                  <p className={"mt-[22px] nunitoregular w-full sm:w-[485px] text-[10px] text-[#2B2F42]"}>Complete your profile to increase visibility to employers and get more matches!</p>
                  <div className={"w-full h-fit sm:mt-0 mt-4 relative"}>
                    <div className={"mt-[9px] w-[10%] absolute z-20 bgds1 h-[9px]  rounded-[10px]"}>
                      <div className={"absolute w-[40px] rounded-full text-white text-[12px] nunitobold flex justify-center items-center h-[40px] bg-[#FE0000] -top-3 -right-4"}>%10</div>
                    </div>
                    <div className={"mt-[9px] w-full h-[9px] bg-[#B0B0B0] rounded-[10px]"}></div>
                  </div>
                  <div className={"flex flex-col w-full justify-between flex-wrap mt-5"}>
                     
                    <div className={"flex flex-wrap w-full flex justify-between flex-row"}>
                    {employers?.map((employer, index) => (
  <div key={index} className="flex items-center mt-6 flex-row gap-[20px]">
    <div className="checkbox-wrapper-12">
      <div className="cbx">
        <input id={`cbx-12-${index}`} type="checkbox" />
        <label htmlFor={`cbx-12-${index}`}></label>
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
          <path d="M2 8.36364L6.23077 12L13 2"></path>
        </svg>
      </div>
    </div>
    <p className="nunitoregular text-[14px] text-[#2B2F42]">{employer}</p>
  </div>
))}

                      
                    </div>
                  </div>
                </div>
    </> );
}

export default ResumeEvaluation;