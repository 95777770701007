import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { JobTitleModel } from '../models/job-title-model';
import { jobtitle, company, workplace, department, jobType, quota } from '../enum/job-title';

const CreateJobPost1 = () => {

    const [form, setForm] = useState<JobTitleModel>();

    const handleOnChange = (e: any) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };


    const navigate = useNavigate();

    const handleNext = () => { 
        navigate('/job-postings/step2');
    };

    return (
        <div className="p-6">
            {/* Form layout in 3 columns */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Left column (1st Column) */}
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">Job Title</label>
                    <select className="block w-full p-3 border border-gray-300 rounded-lg"
                     name = "jobTitle"
                     value={form?.jobTitle}
                     onChange={handleOnChange}
                    >
                        <option>{jobtitle.UX_UI_Designer}</option>
                    </select>

                    <label className="block mt-6 mb-2 text-sm font-medium text-gray-700">Department</label>
                    <select className="block w-full p-3 border border-gray-300 rounded-lg"
                     name="department"
                     value={form?.department}
                     onChange={handleOnChange}
                    >
                        <option>{department.design}</option>
                    </select>

                    <label className="block mt-6 mb-2 text-sm font-medium text-gray-700">Company</label>
                    <select className="block w-full p-3 border border-gray-300 rounded-lg" 
                     name = "company"
                     value={form?.company}
                     onChange={handleOnChange}
                    >
                        <option>{company.slack}</option>
                    </select>

                    <label className="block mt-6 mb-2 text-sm font-medium text-gray-700">Company Website</label>
                    <input
                    name= "companyWebSite"
                        type="text"
                        className="block w-full p-3 border border-gray-300 rounded-lg"
                        placeholder="slack.com"
                        onChange={handleOnChange}
                    />
                </div>

                {/* Middle column (2nd Column) */}
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">Country</label>
                    <select className="block w-full p-3 border border-gray-300 rounded-lg">
                        <option>Turkey</option>
                    </select>

                    <label className="block mt-6 mb-2 text-sm font-medium text-gray-700">City</label>
                    <select className="block w-full p-3 border border-gray-300 rounded-lg">
                        <option>Istanbul</option>
                    </select>

                    <label className="block mt-6 mb-2 text-sm font-medium text-gray-700">Workplace</label>
                    <select className="block w-full p-3 border border-gray-300 rounded-lg"
                    name="workplace"
                    value={form?.workplace}
                     onChange={handleOnChange}
                    >
                        <option>{workplace.onsite}</option>
                    </select>

                    <label className="block mt-6 mb-2 text-sm font-medium text-gray-700">Salary</label>
                    <div className="flex">
                        <input
                        name= "salary"
                            type="text"
                            className="block w-full p-3 border border-gray-300 rounded-lg"
                            placeholder="50,000"
                            onChange={handleOnChange}
                        />
                        <select className="block w-20 p-3 border border-gray-300 rounded-lg ml-2">
                            <option>TL</option>
                        </select>
                    </div>
                </div>

                {/* Right column (3rd Column) */}
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">Job Type</label>
                    <div className="flex flex-wrap space-x-4">
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600" checked />
                            <span className="ml-2">Full time</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" />
                            <span className="ml-2">Part time</span>
                        </label>
                    
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" />
                            <span className="ml-2">Internship</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" />
                            <span className="ml-2">Temporary</span>
                        </label>
                   
                        <label className="inline-flex items-center">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" />
                            <span className="ml-2">Contract</span>
                        </label>
                    </div>

                    <label className="block mt-6 mb-2 text-sm font-medium text-gray-700">Quota</label>
                    <div className="flex flex-wrap space-x-4">
            <label className="inline-flex items-center">
                <input 
                    name="quato"
                    type="checkbox" 
                    className="form-checkbox h-5 w-5 text-red-600" 
                    checked={form?.quota === quota.first}
                    onChange={handleOnChange}
                />
                <span className="ml-2">{quota.first} Candidates</span>
            </label>
            <label className="inline-flex items-center">
                <input 
                name="quato"
                    type="checkbox" 
                    className="form-checkbox h-5 w-5 text-gray-600" 
                    checked={form?.quota === quota.second}
                    onChange={handleOnChange}
                />
                <span className="ml-2">{quota.second} Candidates</span>
            </label>
            <label className="inline-flex items-center">
                <input 
                name="quato"
                    type="checkbox" 
                    className="form-checkbox h-5 w-5 text-gray-600" 
                    checked={form?.quota === quota.third}
                    onChange={handleOnChange}
                />
                <span className="ml-2">{quota.third} Candidates</span>
            </label>
        </div>
                </div>
            </div>

            {/* Next button with SVG */}
            <div className="flex justify-end mt-6">
                <button onClick={handleNext}>
                    <img src="/public/media/svg/general/ileriok.svg" alt="Next" className="w-30 h-30" />
                </button>
            </div>
        </div>
    );
};

export default CreateJobPost1;
