import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateJobPost2 = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        // Bir sonraki sekmeye ilerler
        navigate('/createjobpostlayout/step3');
    };

    return (
        <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left side - Job Description */}
            <div>
                <h2 className="text-xl font-semibold mb-4">Job Description</h2>
                {/* Template selection */}
                <div className="flex space-x-4 mb-4">
                    <label className="inline-flex items-center">
                        <input type="radio" name="template" className="form-radio text-red-500" />
                        <span className="ml-2">Template 1 <span className="text-xs text-gray-500">(Recommended by AI)</span></span>
                    </label>
                    <label className="inline-flex items-center">
                        <input type="radio" name="template" className="form-radio text-red-500" />
                        <span className="ml-2">Template 2 <span className="text-xs text-gray-500">(Recommended by AI)</span></span>
                    </label>
                    <label className="inline-flex items-center">
                        <input type="radio" name="template" className="form-radio text-red-500" />
                        <span className="ml-2">Template 3 <span className="text-xs text-gray-500">(Recommended by AI)</span></span>
                    </label>
                </div>
                {/* Job description editor */}
                <textarea
                    className="w-[766px] p-4 border border-gray-300 rounded-lg h-80 mb-4"
                    placeholder="We are seeking a skilled and passionate professional for the position of UX/UI Designer. As a UX/UI Designer, you will be responsible for analyzing user needs, designing user-friendly interfaces, creating prototypes, and conducting usability tests..."
                />
                <div className="flex space-x-4">
                    <button className="bg-red-500 text-white px-6 py-2 rounded-lg">Save</button>
                    <button className="text-gray-500">Cancel</button>
                </div>
            </div>

            {/* Right side - Skills */}
            <div>
                <h2 className="text-xl font-semibold mb-4">Skills</h2>
                <div className="flex flex-wrap gap-2">
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Metrics</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Team Work</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Project Management</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Creativity</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">UX Design</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">A/B Testing</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">User Testing</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Prototyping</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Realtion Management</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Data Analysis</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Problem Solving</span>
                    <span className="inline-block bg-red-100 text-red-500 px-4 py-2 rounded-full ml-8">Wireframing</span>
                </div>
            </div>

            {/* Floating Next Button */}
            <div className="fixed bottom-10 right-10">
                <button onClick={handleNext}>
                    <img src="/public/media/svg/general/ileriok.svg" alt="Next" className="w-30 h-30" />
                </button>
            </div>
        </div>
    );
};

export default CreateJobPost2;
